import { graphql, useStaticQuery } from "gatsby";
import * as React from "react"; // tslint:disable-line
import { ReflectionsProvider as Provider } from "../../components/context/reflections-context";
import { platformProviderValue } from "@shapeable/web";

const REFLECTIONS_QUERY = graphql`
  query PlatformReflectionsQuery {
    platform {
      allReflections {
        id name path slug __typename _schema { label pluralLabel }
        intro { id plain text }
        openGraph { id image { thumbnails { full { url } } } }
        type { id name slug __typename }
        published
        topics {
          id name slug __typename _schema { label pluralLabel }
          openGraph { id image { url } }
        }
        challenges { 
          id name slug __typename _schema { label pluralLabel }
          openGraph { id image { url } }
        }
        authors {
         id name _schema { label pluralLabel }
         photo { id url static { childImageSharp { gatsbyImageData } } }
        }
      }
    }
  }
`

export const ReflectionsProvider: React.FC<any> = ({ children }) => {
  return (
    <Provider value={ platformProviderValue( useStaticQuery(REFLECTIONS_QUERY), 'allReflections' ) }>{children}</Provider>
  )
}