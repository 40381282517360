import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { BackToTopButton, SliceJumpLinks, ContentEntityIntro, ContentEntityLayout, Grid, HorizontalScroll, OrganisationBioCell, SliceHeaderDivider, useContentSlice, useLang, SliceLayout, SliceLayoutBoundary } from '@shapeable/ui';
import { useOrganisationPartners } from '../../hooks/use-organisation-partners';
import { groupBy, sortBy, mapValues, values, map, kebabCase, get } from 'lodash';
import pluralize from 'pluralize';
import { GREEN_OVERLAY, LIGHT_BIEGE, BANNER_LEAF } from '../../theme';
import { SliceLayoutColorStrip } from '../slice-layouts';
import { EntityTitleHeader } from '@shapeable/web';


// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    [id] {
      scroll-margin-top: 80px;
    }
  `,
});

const HeaderStyles = breakpoints({
  base: css`
  `,
});

const ContentStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)};
  `,
});


const JumpLinkStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(3)} 0;
  `,
  tablet: css`
    margin: ${theme.UNIT(5)} 0;
  `,
});

const JumpLinksSectionStyles = breakpoints({
  base: css`
    background: #FFF;
    padding-top: 0;
  `,
});

const JumpLinksScrollStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});

const GridStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(6)} 0;

    a {
      display: flex;
      justify-content: center;
    }
  `,
});

const HeaderDividerStyles = breakpoints({
  base: css`
    
  `,
});

const OrganisationStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)}; 
    flex-grow: 1;
    img {
      padding: 26px;
    }
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
});

const IntroStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(8)} 0;
  `,
});

const BackToTopButtonStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(10)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
  Content: styled(ContentEntityLayout)`${ContentStyles}`,

  Intro: styled(ContentEntityIntro)`${IntroStyles}`,
  
  JumpLinksSection: styled.div`${JumpLinksSectionStyles}`,
    JumpLinksScroll: styled(HorizontalScroll)`${JumpLinksScrollStyles}`,
    JumpLinks: styled(SliceJumpLinks)`${JumpLinkStyles}`,

  HeaderDivider: styled(SliceHeaderDivider)`${HeaderDividerStyles}`,

  Layout: styled.div`${LayoutStyles}`,

  BackToTopButton: styled(BackToTopButton)`${BackToTopButtonStyles}`,

  Grid: styled(Grid)`${GridStyles}`,
    Organisation: styled(OrganisationBioCell)`${OrganisationStyles}`,
};

export const PageLayoutPartners: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;

  const t = useLang();

  const organisationPartners = sortBy(useOrganisationPartners(), partner => ["knowledge-partner", "talent-partner", "project-partner"].indexOf(get(partner, "organisationStatus.slug")));
  const partnersByStatus = groupBy(organisationPartners, "organisationStatus.name");

  // console.log(organisationPartners);
  
  const { intro } = entity;
  const hasIntro = intro && intro.text;

  const partnerSlices = mapValues(partnersByStatus, (organisations, status) => {
    return useContentSlice({
      id: kebabCase(status),
      title: t(pluralize.plural(status)),
    })
  });

  return (
    <My.Container className={className}>

      <EntityTitleHeader overlayColor={GREEN_OVERLAY} backgroundImage={BANNER_LEAF} entity={entity} />

      <SliceLayoutColorStrip />
      
      <My.JumpLinksSection style={{ backgroundColor: LIGHT_BIEGE }}>
      <SliceLayoutBoundary>
        <My.JumpLinksScroll>
        <My.JumpLinks items={values(partnerSlices)} />
        </My.JumpLinksScroll>
      </SliceLayoutBoundary>
      </My.JumpLinksSection> 
      
      <SliceLayout>
    
      {
        hasIntro &&
        <My.Intro entity={entity} />
      }

      {
        map(partnerSlices, (slice, status) => {
          const partners = partnersByStatus[status];

          return !!partners.length && (
            <My.Layout id={slice.slug} key={status}>
              <My.HeaderDivider entity={slice} />
              <My.Grid spacing={8} maxColumns={3} items={partners.map(org => <My.Organisation entity={org} />)} />
              <My.BackToTopButton />
            </My.Layout>
          )
        })
      }
      </SliceLayout>
      {slices}
    </My.Container>
  )
};
