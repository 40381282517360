import { ThemeDefinition, ThemeOverrides, breakpoints, theme } from "@shapeable/theme";
import { css } from "styled-components";
import { Banner, Color } from "@shapeable/types";

export const ORANGE = '#F7921D';

export const MEDIUM_BLUE = '#2496BD';
export const DARK_BLUE = '#02394B';
export const CONTENT_BACKGROUND = '#FFF';

export const GREEN = '#005D25';
export const LIGHT_GREEN = '#377F43';
export const LIGHT_BLUE = '#00B8D9';
export const BLUE = '#0673BE';
export const DARK_RED = '#990008';
export const LIGHT_BROWN = '#AD9A8A';
export const GOLD = '#FFA200';

export const GREEN_OVERLAY = `${GREEN}AA`;
export const GOLD_OVERLAY = `${GOLD}66`;
export const BLUE_OVERLAY = `${BLUE}EE`;
export const BROWN_OVERLAY = `${LIGHT_BROWN}EE`;

export const DARK_GREEN = '#001709';

export const BRAND_1 = GREEN;
export const BRAND_2 = LIGHT_GREEN;
export const BRAND_3 = LIGHT_BLUE;
export const BRAND_4 = BLUE;
export const BRAND_5 = DARK_RED;
export const BRAND_6 = LIGHT_BROWN;
export const BRAND_7 = GOLD;


export const SESSION_BLUE = '#AEDCE3';
export const SESSION_ORANGE = '#FFA200';
export const SESSION_GREEN = '#C2E5CB';
export const SESSION_YELLOW = '#FFD088';


export const BRAND_COLORS = [BRAND_1, BRAND_2, BRAND_3, BRAND_4, BRAND_5, BRAND_6, BRAND_7];

export const LIGHT_BIEGE = '#F7F5F3';
export const VERY_LIGHT_BROWN = '#D5CDC4';
export const MEDIUM_BROWN = '#796c61';
export const DARK_BROWN = '#685c53';

export const EXTREMELY_LIGHT_BROWN = '#e6e1dc';

export const FOOTER_BACKGROUND = '#D5CDC4';



export const BANNER_LEAF: Banner = {
  id: 'banner-leaf',
  name: 'Banner Leaf',
  slug: 'banner-leaf',
  image: {
    id: 'attneyYxGMrohNN2E',
    url: 'https://res.cloudinary.com/shapeable/image/upload/v1668989824/villars-institute/banner/sub-page-banner-leaf_image__sub-s1-banner_z8crca.jpg',
    width: 2540,
    height: 521,
  },
};


export const BANNER_EVENT: Banner = {
  id: 'banner-event',
  name: 'Banner Event',
  slug: 'banner-event',
  image: {
    id: 'recYRsg6ML0rqtefA_image',
    url: 'https://res.cloudinary.com/shapeable/image/upload/v1668989821/villars-institute/banner/sub-page-banner-event_image__event-banner.jpg',
    width: 1920,
    height: 576,
  },
};

export const BANNER_KNOWLEDGE_HUB: Banner = {
  id: 'banner-knowledge-hub',
  name: 'Banner Knowledge Hub',
  slug: 'banner-knowledge-hub',
  image: {
    id: 'recYRsg6ML0rqtefA_image',
    url: 'http://res.cloudinary.com/shapeable/image/upload/v1677916234/villars/banner/villars-symposium-banner_image__villars-view_ethsmh.jpg.jpg',
    width: 1920,
    height: 576,
  },
};

export const COLOR_GREEN: Color = {
  id: 'green',
  slug: 'green',
  value: GREEN,
};

export const COLOR_LIGHT_BROWN: Color = {
  id: 'light-brown',
  slug: 'light-brown',
  value: LIGHT_BROWN,
};

export const COLOR_BLUE: Color = {
  id: 'blue',
  slug: 'blue',
  value: BLUE,
};


export const themeOverrides: ThemeOverrides = {
  font: {
    family: {
      serif: "'Cormorant Garamond', georgia, serif",
      sans: 'Jost, sans-serif',
      heading: 'Jost, sans-serif',
      copy: 'Jost, sans-serif',
    }
  },
  light: {
    color: {
      primary: GREEN,
      secondary: LIGHT_BROWN,
  
      link: GREEN,
      linkHover: LIGHT_BROWN,
      
      heading: GREEN,
  
      button: GREEN,
      buttonHover: MEDIUM_BROWN,
  
      lightBrand: GOLD,
  
      midBrand: BLUE,
  
      invertedLink: '#FFF',
      invertedLinkActive: '#FFF',
      invertedLinkHover: VERY_LIGHT_BROWN,
  
      
  
      menuActive: GOLD,
  
      invertedMenuHover: '#FFF',
      invertedMenuActive: GOLD,
    },
  },
};


export const FontSizeStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(16)};
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(16)};
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(17)};
  `
});


/* 
--------------------

  Layout Styles
  
  NB: This is the appropriate place to override styling for this specific site, based on BEM-style class name targets 
  
--------------------
*/

export const LayoutStyles = breakpoints({
  base: css`
    ${FontSizeStyles};

    .shp--site-header-layout__navigation {
      font-size: 1.2em;
    }

    .shp--site-menu-bar {
      background: linear-gradient(to bottom, ${GREEN} 0%, ${GREEN}EE 100%), url(${BANNER_LEAF.image.url});
      background-size: cover;
    }
    
    .shp--site-menu-bar__title {
      font-size: ${theme.FONT_SIZE(18)}; 
    }

    .shp--session-layout__presenter {
      // margin-top: -5px;
    }

    .shp--slice-intro .shp--markdown-content {
      font-size: 1em;
      text-align: left;
      line-height: 1.35em;
      font-weight: 300;
      max-width: 900px;
    }

    .shp--content-title {
      font-size: 24px;
    }

    .shp--organisation-powered-by {
      img {
        mix-blend-mode: color-burn;
        filter: grayscale(1);
      }

      &:hover {
        filter: none;
      }
    }

    .shp--entity-grid__filter {
      width: 100%;
    }


  `,
  tablet: css`
    .shp--slice-intro .shp--markdown-content {
      font-size: 1.5em;
    }
  `,
  desktop: css`
    .shp--site-menu-bar {
      opacity: 0.97;
    }
  `
}); 
