import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityTitleHeader } from '@shapeable/web';
import { GREEN_OVERLAY, BANNER_LEAF, COLOR_BLUE, COLOR_LIGHT_BROWN } from '../../theme';
import { createIntroSlice } from '../../data';
import { SliceLayoutColorStrip } from '../slice-layouts';
import { classNames } from '@shapeable/utils';
import { EntitySelect } from '@shapeable/ui';
const cls = classNames('page-layout-strategic-intelligence');


// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const TopicSelectStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
  TopicSelect: styled(EntitySelect)`${TopicSelectStyles}`,
};


export const PageLayoutStrategicIntelligence: PageLayoutComponent = 
(props) => {
  const { className, entity, slices } = props;

  return (
    <My.Container className={cls.name(className)}>
      <EntityTitleHeader overlayColor={GREEN_OVERLAY} backgroundImage={BANNER_LEAF} entity={entity} />
      <SliceLayoutColorStrip />
      {slices}
    </My.Container>
  );
};