import { Organisation } from '@shapeable/types';
import { platformProviderValue } from '@shapeable/web';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { filter, get, includes } from 'lodash';

const ORGANISATION_PARTNERS_QUERY = graphql`
  query PlatformOrganisationPartnersQuery {
    platform {
      allOrganisations(options: { ignoreSiteHeaders: true }) {
        id
        name
        url
        description { html }
        openGraph { image { url } }
        organisationStatus {
          id
          name
          slug
        }
      }
    }
  }`;

export const OrganisationPartnersContext = React.createContext<Organisation[]>(null);
export const OrganisationPartnersProvider: React.FC<any> = ({ children }) => {
  const results = platformProviderValue( useStaticQuery(ORGANISATION_PARTNERS_QUERY), 'allOrganisations', [] );
  const value = filter(results, result => includes(["project-partner", "talent-partner", "knowledge-partner"], get(result, "organisationStatus.slug")));
  return <OrganisationPartnersContext.Provider value={value}>{children}</OrganisationPartnersContext.Provider>;
};