import { 
  SliceColorStrip, PageKnowledgeHubLayout, PageKnowledgeHubLayoutProps,
  SliceColorStripProps, SliceContactForm, SliceContactFormProps, SliceContentEntityQuote, 
  SliceHeaderDivider, SliceHeaderDividerProps, SliceInlineBanner, SliceIntro, createSliceLayout,
 } from '@shapeable/ui';
import { BRAND_COLORS } from '../../theme';
import { SliceSectionColorBlock, SliceSectionColorBlockProps } from '../entities/slice-section-color-block';
import { SliceStrategicIntelligence, SliceStrategicIntelligenceProps } from '../entities/slice-strategic-intelligence-widget';
import { ContactForm } from '../elements/contact-form';

export const SliceLayoutIntroBackground = createSliceLayout(
  SliceInlineBanner, {
    boundary: 'content',
    aspectRatio: {
      base: 21 / 9,
    }
  }
);

export const SliceLayoutKnowledgeHub = createSliceLayout<PageKnowledgeHubLayoutProps>(
  PageKnowledgeHubLayout, {
    boundary: 'content-bleed',
    verticalPadding: 'medium',
    props: {
      filterSize: 'slim',
      filterSpacing: 3,
      showAllTab: true,
      entityTypes: { 
        Post: { filterFieldNames: [ "type", "topics", "challenges", "authors"] }, 
        Video: { filterFieldNames: [ "topics", "challenges", "people" ] } 
      }
    }
  }
);

export const SliceLayoutKeyMessage = createSliceLayout(
  SliceContentEntityQuote, {
    boundary: 'none',
  }
);

export const SliceLayoutStrategicIntelligenceWidget = createSliceLayout<SliceStrategicIntelligenceProps>(
  SliceStrategicIntelligence, {
    boundary: 'none',
  }
);

export const SliceLayoutContactForm = createSliceLayout<SliceContactFormProps>(
  SliceContactForm, {
    props: {
      variant: 'reveal',
      formComponent: ContactForm
    }
  }
);

export const SliceLayoutHeader = createSliceLayout<SliceHeaderDividerProps>(
  SliceHeaderDivider, {
    boundary: 'content',
    props: {
    }
  }
);

export const SliceLayoutColorStrip = createSliceLayout<SliceColorStripProps>(
  SliceColorStrip, {
    boundary: 'none',
    props: {
      colors: BRAND_COLORS
    }
  }
);

export const SliceLayoutIntro = createSliceLayout(
  SliceIntro, {
    verticalPadding: 'small',
  }
);

export const SliceLayoutSection = createSliceLayout(
  SliceSectionColorBlock, {
    boundary: 'none',
  }
);

export const SliceLayoutSectionReverse = createSliceLayout<SliceSectionColorBlockProps>(
  SliceSectionColorBlock, {
    boundary: 'none',
    props: {
      isReversed: true
    }
  }
);