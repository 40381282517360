module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"268407012211457"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-W03MQ9MZKR"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ef4f10e6683119b820b238ce32779168"},
    },{
      plugin: require('../node_modules/@shapeable/gatsby-theme-shapeable-web/gatsby-browser.js'),
      options: {"plugins":[],"redirects":{"/alpine-inspiration-villars-sur-ollon":"/posts/alpine-inspiration-villars-sur-ollon","/systems-leadership-for-all-generations":"/posts/systems-leadership-for-all-generations","/empowering-future-systems-leaders":"/posts/empowering-future-systems-leaders","/4-questions-to-lee-howell-executive-director-of-the-villars-institute":"/posts/4-questions-to-lee-howell-executive-director-of-the-villars-institute","/villars-institute-fellows.pdf":"/Villars%20Fellows.pdf","/Villars-Music-Academy.pdf":"/Villars%20Music%20Academy.pdf"},"fonts":["Cormorant+Garamond:ital,wght@0,500;1,500","Jost:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600"],"entities":{"Post":{"filterFieldNames":["topics"]},"Event":{},"Video":{},"Topic":{},"Challenge":{},"FeedEntry":{},"Reflection":{}},"siteMetadata":{"title":"Villars Institute","description":"","image":"","video":"","twitter":"","threads":"","linkedin":"","facebook":"","flickr":"","tiktok":"","youtube":"","instagram":"","name":"","logo":"","openGraphTitleTemplate":"%s","url":"https://villarsinstitute.org","titleTemplate":"%s - %s","siteUrl":"https://villarsinstitute.org"}},
    }]
