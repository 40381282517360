import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { GREEN_OVERLAY, LIGHT_BIEGE, BANNER_LEAF } from '../../theme';
import { BackToTopButton, ContentEntityContent, SliceJumpLinks, HorizontalScroll, SliceLayoutBoundary } from '@shapeable/ui';
import { filter, get, map } from 'lodash'
import { SliceLayoutColorStrip } from '../slice-layouts';
import { EntityTitleHeader } from '@shapeable/web';

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    .shp--slice-header-divider {
      margin-top: ${theme.UNIT(10)};
    }
  `,
  tablet: css`
    .shp--slice-header-divider {
      margin-top: ${theme.UNIT(20)};
    }
  `,
  desktop: css`
    .shp--slice-header-divider {
      margin-top: ${theme.UNIT(24)};
    }
  `

});

const ContentStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)};
  `,
});

const JumpLinksSectionStyles = breakpoints({
  base: css`
    padding-top: 0;
  `,
});

const JumpLinksScrollStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});

const JumpLinksStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(18)};
    padding: ${theme.UNIT(2)};
    justify-self: center;

    li {
      padding-left: ${theme.UNIT(6)};
      padding-right: ${theme.UNIT(6)};
    }

    li:first-child {
      padding-left: ${theme.UNIT(1)};
    }

    li:last-child {
      background: none;
    }
  `,
  tablet: css`
    padding: ${theme.UNIT(4)};
  `
});

const BackToTopButtonStyles = breakpoints({
  base: css`
    justify-self: center;
    width: 100%;
    max-width: 280px;
  `,
});

const FooterStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.UNIT(8)};
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    JumpLinksSection: styled.div`${JumpLinksSectionStyles}`,
      JumpLinksScroll: styled(HorizontalScroll)`${JumpLinksScrollStyles}`,
      JumpLinks: styled(SliceJumpLinks)`${JumpLinksStyles}`,

    Content: styled(ContentEntityContent)`${ContentStyles}`,

    Footer: styled.div`${FooterStyles}`,
      BackToTopButton: styled(BackToTopButton)`${BackToTopButtonStyles}`,
};

export const PageLayoutFaq: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;

  const jumpSlices = map(filter(entity.slices, slice => get(slice, 'layout.slug') === 'header'), slice => {
    // shorten names for jump links
    const name = slice.name.replace(/(Villars Institute|Villars)/ig, '').replace(/SYSTEMS LEADERSHIP ONLINE COURSE PROGRAM/ig, 'Systems Leadership Course');

    return {
      ...slice,
      name,
      title: name
    };
  });
  
  return (

    <My.Container className={className}>
      <EntityTitleHeader overlayColor={GREEN_OVERLAY} backgroundImage={BANNER_LEAF} entity={entity} />
      <SliceLayoutColorStrip />

      <My.JumpLinksSection style={{ backgroundColor: LIGHT_BIEGE }}>
        <My.JumpLinksScroll>
        <My.JumpLinks items={jumpSlices} />
        </My.JumpLinksScroll>
      </My.JumpLinksSection> 

      <SliceLayoutBoundary>
        <My.Content entity={entity} />
      </SliceLayoutBoundary>
      {slices}

      <My.Footer>
        <My.BackToTopButton />
      </My.Footer>
    </My.Container>
  )
};
